<template>
  <div class="mod-user">
    <!-- 搜索栏 -->
    <div>
      <el-form :inline="true" :model="dataForm" size="small">
        <el-form-item label="关键字">
          <el-input v-model="dataForm.keyword" placeholder="支持模糊查询(账号,手机号,真实姓名)" clearable />
        </el-form-item>
        <el-form-item label="订单创建时间" prop="time">
          <el-date-picker
              v-model="dataForm.time"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetimerange"
              range-separator="-"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              :clearable="false"
              :editable="false"
              :picker-options="$store.state.common.pickerOptionsTime"
          />
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button @click="() => this.dataForm = this.$options.data().dataForm" type="info">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 表格栏 -->
    <div class="tableBox">
      <el-table :data="dataList"
                ref="table"
                @row-click="rowClick"
                :row-style="selectedHighlight"
                :height="tableHeight"
                size="small"
                v-loading="dataListLoading"
                border
                style="width: 100%;font-weight: 900;"
                show-summary
                :summary-method="getSummaries">
        <el-table-column type="index" align="center" label="序号" fixed="left" width="60" />
        <el-table-column prop="username" align="center" label="名称" fixed="left" :show-overflow-tooltip="true" />
        <el-table-column prop="orderCreateNum" align="center" label="订单创建总数" fixed="left" :show-overflow-tooltip="true" />
        <el-table-column label="订单列表-订单审核" align="center">
          <el-table-column prop="statusUnchecked" align="center" label="未审核" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <el-link type="info">{{ scope.row.statusUnchecked }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="statusChecked" align="center" label="已审核" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <el-link type="success">{{ scope.row.statusChecked }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="statusOvertime" align="center" label="超时" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              <el-link type="danger">{{ scope.row.statusOvertime }}</el-link>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="订单列表-安排车辆" align="center">
          <el-table-column prop="operateModeUnchecked" align="center" label="未分配" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              <el-link type="info">{{ scope.row.operateModeUnchecked }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="operateModeChecked" align="center" label="已分配" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              <el-link type="success">{{ scope.row.operateModeChecked }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="operateModeOvertime" align="center" label="超时" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              <el-link type="danger">{{ scope.row.operateModeChecked }}</el-link>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="订单运输流程-司机操作APP" align="center">
          <el-table-column prop="confirmStatusUnchecked" align="center" label="未处理" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              <el-link type="info">{{ scope.row.confirmStatusUnchecked }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="confirmStatusChecked" align="center" label="已处理" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              <el-link type="success">{{ scope.row.confirmStatusChecked }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="confirmStatusOvertime" align="center" label="超时" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              <el-link type="danger">{{ scope.row.confirmStatusChecked }}</el-link>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="异常管理-异常状态" align="center">
          <el-table-column prop="errorCreateNum" align="center" label="异常数" :show-overflow-tooltip="true" />
          <el-table-column prop="errorStatusUnchecked" align="center" label="未处理" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              <el-link type="info">{{ scope.row.errorStatusUnchecked }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="errorStatusChecked" align="center" label="已处理" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              <el-link type="success">{{ scope.row.errorStatusChecked }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="errorStatusOvertime" align="center" label="超时" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              <el-link type="danger">{{ scope.row.errorStatusOvertime }}</el-link>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="订单列表-回单核对" align="center">
          <el-table-column prop="receiptStatusUnchecked" align="center" label="未核对" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              <el-link type="info">{{ scope.row.receiptStatusUnchecked }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="receiptStatusChecked" align="center" label="已核对" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              <el-link type="success">{{ scope.row.receiptStatusChecked }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="receiptStatusOvertime" align="center" label="超时" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              <el-link type="danger">{{ scope.row.receiptStatusOvertime }}</el-link>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="订单费用核对-客服对账" align="center">
          <el-table-column prop="careStatusUnchecked" align="center" label="未核对" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              <el-link type="info">{{ scope.row.careStatusUnchecked }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="careStatusChecked" align="center" label="已核对" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              <el-link type="success">{{ scope.row.careStatusChecked }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="careStatusOvertime" align="center" label="超时" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              <el-link type="danger">{{ scope.row.careStatusOvertime }}</el-link>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="订单费用核对-主管对账" align="center">
          <el-table-column prop="deptStatusUnchecked" align="center" label="未核对" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              <el-link type="info">{{ scope.row.deptStatusUnchecked }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="deptStatusChecked" align="center" label="已核对" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              <el-link type="success">{{ scope.row.deptStatusChecked }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="deptStatusOvertime" align="center" label="超时" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              <el-link type="danger">{{ scope.row.deptStatusOvertime }}</el-link>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="订单费用核对-财务对账" align="center">
          <el-table-column prop="costStatusUnchecked" align="center" label="未核对" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              <el-link type="info">{{ scope.row.costStatusUnchecked }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="costStatusChecked" align="center" label="已核对" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              <el-link type="success">{{ scope.row.costStatusChecked }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="costStatusOvertime" align="center" label="超时" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              <el-link type="danger">{{ scope.row.costStatusOvertime }}</el-link>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="订单收款核对" align="center">
          <el-table-column prop="writeStatusCollectionUnchecked" align="center" label="未核对" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <el-link type="info">{{ scope.row.writeStatusCollectionUnchecked }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="writeStatusCollectionChecked" align="center" label="已核对" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              <el-link type="success">{{ scope.row.writeStatusCollectionChecked }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="writeStatusCollectionOvertime" align="center" label="超时" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              <el-link type="danger">{{ scope.row.writeStatusCollectionOvertime }}</el-link>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="订单付款核对" align="center">
          <el-table-column prop="writeStatusPaymentUnchecked" align="center" label="未核对" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              <el-link type="info">{{ scope.row.writeStatusPaymentUnchecked }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="writeStatusPaymentChecked" align="center" label="已核对" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              <el-link type="success">{{ scope.row.writeStatusPaymentChecked }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="writeStatusPaymentOvertime" align="center" label="超时" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              <el-link type="danger">{{ scope.row.writeStatusPaymentOvertime }}</el-link>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
                     :page-sizes="[20, 50, 100]" :page-size="pageSize" :total="totalPage"
                     layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>

<script>
let currentDate = new Date()
export default {
  data() {
    return {
      // 搜索信息
      dataForm: {
        time: [
          new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0),
          new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59)
        ]
      },
      // 表格
      dataList: [],
      getIndex: -1,
      dataListLoading: false,
      columns: [
        { label: '名称', name: 'configName' },
        { label: '编号', name: 'configNo' },
        { label: '平台类型', name: 'platformType' },
        { label: '状态', name: 'status' },
        { label: '创建时间', name: 'createTime' },
        { label: '更新时间', name: 'updateTime' },
        { label: '备注', name: 'remark' },
      ],
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      tableHeight: '75vh'
    }
  },
  components: {},
  created() {
    this.getDataList()
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.table.doLayout()
    })
  },
  computed: {
    dictTypeMap: {
      get() {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  methods: {
    /**
     * 点击表格变色
     * @param row 当前行数据
     * @param rowIndex 当前行号
     * @returns {{"background-color": string}}
     */
    selectedHighlight({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    rowClick(row) {
      this.getIndex = row.index
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            return isNaN(value) ? prev : prev + curr;
          }, 0);
          sums[index] += ' ';
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },

    /**
     * 每页大小
     * @param val 当前选中页大小
     */
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },

    /**
     * 当前页
     * @param val 当前选中的页数
     */
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },

    // 获取数据列表
    getDataList() {
      let that = this
      if (!(this.dataForm.time)) {
        this.$message.error('请选择订单创建时间')
      }
      this.dataListLoading = true
      this.dataForm.page = this.pageIndex
      this.dataForm.pageSize = this.pageSize
      this.dataForm.orderStartTime = this.dataForm.time[0]
      this.dataForm.orderEndTime = this.dataForm.time[1]

      // 执行
      that.$http({
        url: that.$http.adornUrl('/transactionAgentStatistics/statistics'),
        method: 'get',
        params: that.$http.adornParams(this.dataForm)
      }).then(({ data }) => {
        if (data) {
          that.dataList = data.list
          that.totalPage = data.total
        } else {
          that.dataList = []
          that.totalPage = 0
        }
      }).finally(() => {
        that.dataListLoading = false
      })
    },
  }
}
</script>

<style lang="less" scoped>
.el-link{
  font-weight: 900;
}
</style>
